import { IconType } from '@innovamat/glimmer-icons';
import { SitemapTreeQuery } from '@innovamat/glow-api-client';
import { TFunction } from 'i18next';
import type {
  FlattenedSitemapItem,
  ProcessedSectionItem,
  ProcessedSitemapSection,
  ProcessedSitemapTree,
  SitemapTree,
  SitemapTreeItem,
  SitemapTreeSection,
} from '../types';
import { NAV_TYPE, NavType } from './navigation';

const sectionIcons = {
  '/general': 'HomeIcon',
  '/curriculum/home': 'InnovamatLogoIcon',
  '/practice/home': 'AppletIcon',
  '/learning/home': 'EvaluationIcon',
  '/personas': 'StudentsIcon',
};

export const getIcon = (navType: NavType, section: SitemapTreeSection) => {
  if (section.icon) return section.icon as IconType;
  if (navType === NAV_TYPE.Classroom || navType === NAV_TYPE.AdminClassroom) {
    return sectionIcons[section.route as keyof typeof sectionIcons] as IconType;
  }
  return undefined;
};

export const getGeneralSection = (
  section: SitemapTreeSection,
  t: TFunction<'translation', undefined, 'translation'>,
  navType: NavType,
  index: number
) => {
  const item = section.items?.[0];

  if (item?.isOnlyForClassroom && navType !== NAV_TYPE.Classroom) {
    return null;
  }

  return {
    menuItemType: 'item',
    id: `${section.value}-${section.route}-${index}`,
    value: item?.value!,
    route: section.route,
    icon: getIcon(navType, section),
    demoPageId: item?.demoPageId,
    fullPageId: item?.fullPageId,
  };
};

export const getPersonsSection = (
  t: TFunction<'translation', undefined, 'translation'>
) => ({
  value: t('sidebar.items.people'),
  icon: 'StudentsIcon',
  route: '/personas',
  items: [
    {
      type: 'link',
      value: t('sidebar.items.students'),
      urlName: '/students',
      status: 'ACTIVE',
    },
    {
      type: 'link',
      value: t('sidebar.items.teachers'),
      urlName: '/teachers',
      status: 'ACTIVE',
    },
  ],
});

export const getAssessmentAndInterventionSection = (
  t: TFunction<'translation', undefined, 'translation'>,
  assessmentAndIntervention?: boolean,
  earlyIntervention?: boolean
) => {
  if (!assessmentAndIntervention) {
    return null;
  } else {
    return {
      value: t('sidebar.items.assessmentAndIntervention'),
      icon: 'EvaluationIcon',
      route: '/assessment-and-intervention',
      items: [
        {
          type: 'link',
          value: t('sidebar.items.tests-and-results'),
          urlName: '/tests-and-results',
          status: 'ACTIVE',
        },
        {
          type: 'link',
          value: t('reports.catch-up-dashboard.header.title'),
          urlName: '/reports/intervention',
          status: earlyIntervention ? 'ACTIVE' : 'INACTIVE',
        },
      ],
    };
  }
};

export const getReportsSection = (
  tree: SitemapTreeQuery['sitemapTree'],
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const digitalPractice = tree?.find((t) => t?.route?.includes('/practice'));
  const reportsRoutes = digitalPractice?.items?.filter((item) =>
    item?.route?.includes('reports')
  );

  return {
    value: t('sidebar.items.reports'),
    icon: 'CbStatisticProbabilityIcon',
    route: '/reports',
    items: reportsRoutes?.map((item) => ({
      type: 'link',
      value: item?.value || '',
      urlName: item?.route || '',
      status: 'ACTIVE',
    })),
  };
};

export const flattenSitemaps = (sitemaps: ProcessedSitemapTree) => {
  if (!sitemaps) {
    return undefined;
  }
  const flattened = [] as FlattenedSitemapItem[];
  sitemaps.forEach((sitemapSection: ProcessedSitemapSection) => {
    if (sitemapSection?.menuItemType === 'item') flattened.push(sitemapSection);
    if (sitemapSection?.items) {
      sitemapSection.items.forEach((item) => {
        if (typeof item === 'object' && item !== null) {
          flattened.push(item);
        }
      });
    }
  });
  return flattened;
};

export const isGroupFollowedByOnlyForClassroomOrGroups = (
  section: SitemapTreeSection,
  index: number
) => {
  if (section.items[index]?.type !== 'group') return false;

  return section.items
    ?.slice(index + 1)
    .every(
      (nextItem) =>
        !nextItem || nextItem.isOnlyForClassroom || nextItem.type === 'group'
    );
};

export const getExtendedTree = (
  navType: NavType,
  tree: SitemapTreeQuery['sitemapTree'],
  t: TFunction<'translation', undefined, 'translation'>,
  isInStudentView: boolean,
  permissions?: {
    assessmentAndIntervention?: boolean;
    earlyIntervention?: boolean;
  }
): SitemapTree => {
  // First of all, we need to remove the sections and items that are not active
  const cleanedTree = tree?.map((section) => {
    if (!section) return null;

    section.items = section?.items?.filter((item) => item?.status === 'ACTIVE');

    // If the navigation type is not CLASSROOM, we need to remove the items that are only for classroom
    if (navType !== NAV_TYPE.Classroom && navType !== NAV_TYPE.AdminClassroom) {
      section.items = section.items?.filter((item, index) => {
        const isOnlyForClassroom = item?.isOnlyForClassroom;

        return (
          !isOnlyForClassroom &&
          !isGroupFollowedByOnlyForClassroomOrGroups(section as any, index)
        );
      });
    }

    if (section?.items?.length === 0) return null;
    if (section?.items?.every((item) => item?.status === 'INACTIVE'))
      return null;

    return section;
  });

  const personsSection = getPersonsSection(t);

  // If the navigation type is CLASSROOM, we need to add the assessment and intervention and the persons section
  if (navType === NAV_TYPE.Classroom && !isInStudentView) {
    const assessmentAndIntervention = getAssessmentAndInterventionSection(
      t,
      permissions?.assessmentAndIntervention,
      permissions?.earlyIntervention
    );

    return [
      ...(cleanedTree ?? []),
      assessmentAndIntervention,
      personsSection,
    ] as SitemapTree;
  }

  // If the navigation type is ADMINCLASSROOM, we need to add the persons and reports section
  if (navType === NAV_TYPE.AdminClassroom) {
    const reportsSection = getReportsSection(tree, t);
    return [personsSection, reportsSection] as SitemapTree;
  }

  return cleanedTree as unknown as SitemapTree;
};

export const transformLearningSectionToFlatArray = (
  extendedTree: SitemapTree
) => {
  const result = extendedTree
    .map((section) => {
      if (!isLearningSection(section)) return null;

      // "Section" to "Hole Menu" transformation
      // Group type -> Section type

      const sectionItems = [] as ProcessedSectionItem[];
      let currentGroup = null as ProcessedSitemapSection | null;

      section.items?.forEach((item: SitemapTreeItem, index: number) => {
        if (!item) return;

        if (item.type === 'group') {
          if (currentGroup) {
            sectionItems.push(currentGroup);
          }
          currentGroup = {
            menuItemType: 'section',
            id: `${item.value}-${index}`,
            value: item.value,
            items: [],
          };
        } else {
          const newItem =
            item.linkItemType === 'EXTERNAL'
              ? getExternalMenuItem(item, index)
              : getMenuItem(item, index);

          if (currentGroup) {
            currentGroup.items?.push(newItem);
          } else {
            sectionItems.push(newItem);
          }
        }
      });

      if (currentGroup) {
        sectionItems.push(currentGroup);
      }

      return sectionItems;
    })
    .flat();

  return result as ProcessedSitemapTree;
};

export const getExternalMenuItem = (
  item: SitemapTreeItem,
  index: number
): ProcessedSectionItem => ({
  id: `${item.value}-${index}`,
  value: item.value,
  route: item.url,
  openNewTab: true,
});

export const getMenuItem = (
  item: SitemapTreeItem,
  index: number
): ProcessedSectionItem => ({
  id: `${item.value}-${index}`,
  program: item.program,
  value: item.value,
  route: item.urlName || item.route,
  demoPageId: item.demoPageId,
  fullPageId: item.fullPageId,
  menuItemType: 'item',
});

export const isGeneralSection = (section: SitemapTreeSection): boolean =>
  section?.route === '/general';

export const isLearningSection = (section: SitemapTreeSection): boolean =>
  section?.route === '/learning/home';
