import { UseQueryResult, useQuery } from '@tanstack/react-query';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { snack } from '@innovamat/glimmer-components';
import { Region, getLocalesForPrepr } from '@innovamat/localization';
import { Option, Session } from '@innovamat/resource-viewer';
import {
  DigitalGuide,
  Printable,
  SmartGuideType,
  SpaceSession,
  earlyYearClassroomLifeDigitalGuideMapper,
  earlyYearDigitalGuideMapper,
  getData,
  getModel,
  getSmartGuide,
  mapResponseSpace,
  secondaryDigitalGuideMapper,
} from '@innovamat/smart-guides';
import { useEffect } from 'react';
import { useUser } from '../../user-management';

type Props = {
  id: string;
  locale: string | undefined;
  enabled: boolean;
  session: Session;
};

const mapPrintables = (resourcePrintables: Option[]): Printable[] => {
  return resourcePrintables.map((printable) => {
    return {
      url: printable.href,
      value: printable.label,
      isDisabled: printable.isDisabled,
    } as Printable;
  });
};

const BASE_FIELDS = 'items{variations{cdn_files},generic_image{cdn_files}}';

export type PreprResponse =
  | DigitalGuide
  | SpaceSession
  | SmartGuideType
  | undefined;

export default function useGetPreprResource({
  id,
  locale,
  enabled,
  session,
}: Props): UseQueryResult<PreprResponse> {
  const { user } = useUser();
  const region = user?.region as Region;

  const key = ['locale', id, locale];

  const query = useQuery<PreprResponse, Error>({
    queryKey: key,
    queryFn: async () => {
      const model = await getModel(id);
      const locales = getLocalesForPrepr(region, locale!);

      if (
        model === 'SmartGuidePrimaria' ||
        model === 'SmartGuideTaller' ||
        model === 'SmartGuideSecundaria' ||
        model === 'SmartGuideOpen'
      ) {
        const printables = mapPrintables(session.printables);

        return getSmartGuide(
          locales,
          id,
          model,
          printables,
          session.assessableLists,
          user?.id,
          session.standardContent,
          region
        );
      }

      const response = await getData(id, BASE_FIELDS);

      switch (model) {
        case 'EarlyYearsSpace': {
          return await earlyYearDigitalGuideMapper(response, locale!, region!);
        }
        case 'EarlyYearsClassroomLife': {
          return await earlyYearClassroomLifeDigitalGuideMapper(
            response,
            locale!,
            region!
          );
        }
        case 'EarlyYearsAllSpace': {
          return await mapResponseSpace(response, locale!, region!);
        }
        case 'SecondaryEducationGuide': {
          return await secondaryDigitalGuideMapper(response, locale!, region!);
        }
        default:
          return undefined;
      }
    },
    enabled,
    gcTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (query.isError) {
      snack.error(query.error?.message);
    }
  }, [query.isError]);

  return query;
}
