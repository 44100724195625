import { useIsMutating } from '@tanstack/react-query';

import { Grid, Grounder } from '@innovamat/glimmer-components';

import { useUser } from '../../../../user-management';

import { Shortcuts, ShortcutsSkeleton } from '../../../../contents';
import { MyClasses } from '../../../components/my-classes';
import { MySchool } from '../../../components/my-school';

import { LandingPageAdvisorSkeleton } from './landing-page-advisor-skeleton';
import { SearchOrganization } from './search-organization';

import { Organization } from '@innovamat/glow-api-client';
import { useState } from 'react';
import { ColWithMargin, Container } from '../styles';

function LandingPageAdvisor(): JSX.Element {
  const { user } = useUser();

  const NUM_OF_MUTATIONS = 0;
  const isMutating = useIsMutating() > NUM_OF_MUTATIONS;

  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >(user?.organization as Organization);

  const showGeneralSkeleton = isMutating || !user || !selectedOrganization;

  return (
    <>
      <Container>
        <Grid.Row justifyContent="center">
          <ColWithMargin md={10} lg={8}>
            <SearchOrganization
              onChangeOrganization={setSelectedOrganization}
            />
          </ColWithMargin>
        </Grid.Row>
      </Container>

      {showGeneralSkeleton ? (
        <LandingPageAdvisorSkeleton />
      ) : (
        <Container>
          <Grid.Row justifyContent="center">
            <ColWithMargin md={10} lg={8}>
              <MySchool organizationId={selectedOrganization?.id!} />
            </ColWithMargin>

            <Grid.Col md={10} lg={8}>
              <MyClasses />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row justifyContent="center">
            <Grid.Col md={10} lg={8}>
              {user ? (
                <Shortcuts
                  organizationId={user.organizationId}
                  roles={user.roles}
                />
              ) : (
                <ShortcutsSkeleton />
              )}
            </Grid.Col>
          </Grid.Row>
          <Grounder />
        </Container>
      )}
    </>
  );
}

export { LandingPageAdvisor };
