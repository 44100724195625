import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFlag } from '@innovamat/flags';

import { useUser } from '../../user-management';
import { ProcessedSectionItem, ProcessedSitemapTree } from '../types';
import { useOrganization } from '../../classrooms-management';

type Props = {
  showAdminDashboard: boolean;
};

export const useGetAdminSitemap = ({
  showAdminDashboard,
}: Props): ProcessedSitemapTree => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { organization } = useOrganization();

  const featureFlagUser = {
    identifier: '',
    email: user?.email ?? '',
    custom: {
      schoolId: user?.organizationId ?? '',
    },
  };

  const { value: shouldShowCurrentMaterialsFlag } = useFlag(
    'shouldShowCurrentMaterials',
    false,
    featureFlagUser
  );

  const { value: showFamilyPayments } = useFlag(
    'showfamilypayments',
    false,
    featureFlagUser
  );

  const shouldIncludeFamilyPayments = showFamilyPayments;

  const getOrdersReturnsMenuItems = (
    shouldIncludeFamilyPayments: boolean,
    shouldIncludeCurrentMaterials: boolean
  ): ProcessedSectionItem[] => {
    const items: ProcessedSectionItem[] = [];

    if (shouldIncludeCurrentMaterials) {
      items.push({
        id: 'current-materials',
        value: t('sidebar.items.currentMaterial'),
        route: '/orders-returns/current-materials',
      });
    }

    if (shouldIncludeFamilyPayments) {
      items.push({
        id: 'family-payments',
        value: t('sidebar.items.familyPayments'),
        route: '/orders-returns/family-payments',
      });
    }

    items.push({
      id: 'history',
      value: t('sidebar.items.ordersReturnsHistory'),
      route: '/orders-returns/history',
    });

    return items;
  };

  const sitemap = useMemo(() => {
    const newSitemap: ProcessedSitemapTree = [
      {
        menuItemType: 'item',
        id: `classrooms`,
        value: t('sidebar.items.classrooms-list'),
        icon: 'ClassIcon',
        route: '/classrooms',
      },
      {
        menuItemType: 'section',
        id: `school-people`,
        value: t('sidebar.items.people'),
        icon: 'StudentsIcon',
        items: [
          {
            id: `school-students`,
            value: t('sidebar.items.students'),
            subtle: organization ? `(${organization?.numberOfStudents})` : '',
            route: '/school-students',
          },
          {
            id: `deleted-students`,
            value: t('sidebar.items.deletedStudents'),

            subtle: organization
              ? `(${organization?.numberOfDeletedStudents})`
              : '',
            route: '/deleted-students',
          },
          {
            id: `school-teachers`,
            value: t('sidebar.items.teachers'),
            subtle: organization ? `(${organization?.numberOfTeachers})` : '',
            route: '/teachers',
          },
        ],
      },

      {
        menuItemType: 'item',
        id: `imports`,
        value: t('sidebar.items.imports-section'),
        route: '/imports',
        icon: 'ImportIcon',
      },
      {
        menuItemType: 'section',
        id: 'orders-returns',
        value: t('sidebar.items.ordersAndReturns'),
        icon: 'OrdersIcon',
        items: getOrdersReturnsMenuItems(
          shouldIncludeFamilyPayments,
          shouldShowCurrentMaterialsFlag
        ),
      },
    ];

    if (showAdminDashboard) {
      const positionAfterSchoolPeople = 2;

      newSitemap.splice(positionAfterSchoolPeople, 0, {
        menuItemType: 'item',
        id: `admin-dashboard`,
        value: t('sidebar.items.adminDashboard'),
        route: '/admin-dashboard',
        icon: 'CbStatisticProbabilityIcon',
      });
    }

    return newSitemap;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organization,
    t,
    shouldIncludeFamilyPayments,
    shouldShowCurrentMaterialsFlag,
    showAdminDashboard,
  ]);

  return sitemap;
};
