import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUser } from '../../../../user-management';
import { useDebounce, useEventListener } from '@innovamat/hooks';
import {
  SchoolStudentsDocument,
  SchoolStudentsQuery,
  useGlowApiClient,
  SchoolStudentsQueryVariables,
} from '@innovamat/glow-api-client';
import { SchoolStudent } from '../types/student';
import { sortStudentsList } from '../utils/sort-students';
import { useSchoolStudents } from '../providers/school-students-provider';

type UseSchoolStudents = {
  students: SchoolStudent[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
};

const getValueForSearch = (value: string): string => {
  return value.length >= 3 ? value : '';
};

function useListSchoolStudents(): UseSchoolStudents {
  const { user } = useUser();
  const { search, isDeletedStudentsPage } = useSchoolStudents();
  const organizationId = user?.organizationId ?? '';

  const { graphqlClient } = useGlowApiClient();

  const [pageSize] = useState(20);
  const debouncedSearch = useDebounce(search, 200);

  const getVariables = (newPage: number): SchoolStudentsQueryVariables => ({
    body: {
      organizationId: organizationId,
      page: newPage,
      pageSize,
      name: getValueForSearch(debouncedSearch),
      deleted: isDeletedStudentsPage,
    },
  });

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        'school-students',
        getValueForSearch(debouncedSearch),
        isDeletedStudentsPage,
      ],
      queryFn: ({ pageParam = 1 }) => {
        const variables = getVariables(pageParam);
        return graphqlClient.request(SchoolStudentsDocument, variables);
      },
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.schoolStudents?.length === 0) {
          return undefined;
        }
        return allPages.length + 1;
      },
      initialPageParam: 1,
      staleTime: Infinity,
    });

  const sortedStudents = useMemo(() => {
    const pages = data?.pages as SchoolStudentsQuery[];
    const students = (pages?.flatMap((page) => page?.schoolStudents) ??
      []) as SchoolStudent[];
    return students.sort(sortStudentsList);
  }, [data]);

  const handleScroll = useCallback(() => {
    const isScrollInTheBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

    if (isScrollInTheBottom && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, fetchNextPage]);

  useEventListener('scroll', handleScroll);

  const handleFetchNextPage = (): void => {
    fetchNextPage();
  };

  return {
    students: sortedStudents,
    isLoading,
    isFetchingNextPage,
    fetchNextPage: handleFetchNextPage,
    hasNextPage,
  };
}

export { useListSchoolStudents as useSchoolStudents };
