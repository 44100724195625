import { useQueryClient } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';

import { snack } from '@innovamat/glimmer-components';

import type { Organization } from '@innovamat/glow-api-client';
import { useChangeOrganizationMutation } from '@innovamat/glow-api-client';

import { USE_USER_KEY, User, useUser } from '../../../../user-management';
import type { Organization as UserOrganization } from '../../../../user-management/types';
import { SearchOrganizationInput } from '../../../components/search-organization-input';
import { updateCurrentUserData } from '../../../utils/update-current-user-data';

type Props = {
  onChangeOrganization: (organization: Organization) => void;
};

const SearchOrganization = ({ onChangeOrganization }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useUser();

  const queryClient = useQueryClient();

  const changeOrganizationMutation = useChangeOrganizationMutation();

  const handleSelect = (organization: Organization): void => {
    changeOrganizationMutation.mutate(
      { body: { schoolId: organization.id!, userId: user?.id! } },
      {
        onSuccess: () => {
          onChangeOrganization(organization);
          updateCurrentUserData(organization);

          queryClient.setQueryData<User | undefined>(
            USE_USER_KEY,
            (oldData) => {
              if (!oldData) return oldData;
              return {
                ...oldData,
                organizationId: organization.id!,
                organizationName: organization.name!,
                organization: organization as UserOrganization,
              };
            }
          );
        },
        onError: () => {
          snack.error(t('errors.organizations.changeOrganization'));
        },
      }
    );
  };

  return <SearchOrganizationInput onSelect={handleSelect} />;
};

export { SearchOrganization };
