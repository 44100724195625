import { useLocation, useParams } from 'react-router-domv6';
import styled from '@emotion/styled';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import {
  GeneralProgress,
  WeeklyResults,
  useCurrentNavigationValues,
  useUser,
  CatchUpDashboard,
  useNavigation,
  ErrorPage,
  useReportsFullScreen,
  KnowMoreDrawer,
  ERROR_TYPE,
  LAST_YEAR_REPORTS_URL,
  APP_PATHS,
} from '@innovamat/ga-features';
import { useEventLogging } from '@innovamat/event-logging';
import {
  LastYearReportsQuery,
  useCourses,
  useLastYearReportsQuery,
} from '@innovamat/glow-api-client';
import { Stage, stages } from '@innovamat/radiance-utils';
import { useFlag } from '@innovamat/flags';
import HeaderReports from './header-reports';
import React, { useEffect, useState } from 'react';

type BaseProps = {
  title: string;
  description: string;
  descriptionTestId: string;
};

type HeaderReportsProps = BaseProps & {
  actions: JSX.Element;
  descriptionLink: string;
};

const Container = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: ${({ theme }) => theme.breakpoints.xl};
    margin: 0 auto;
    display: block;
  }
`;

const TableContainer = styled.div`
  position: relative;
`;

const Actions = styled.div`
  margin-right: 24px;
  margin-bottom: 10px;
`;

export function ReportsNavigationWrapper(): JSX.Element {
  const { t } = useTranslation();
  const { submenu, classroomId: id } = useParams();

  const { setEventData } = useEventLogging();
  const { currentClassroom, courseOrder, currentRoute } =
    useCurrentNavigationValues();
  const { user } = useUser();
  const { courses } = useCourses({
    regionCode: user?.region!,
    organizationId: user?.organizationId!,
  });
  const educationalStage = stages.getStageFromOrder(courseOrder || 0) as Stage;
  const isSecundariaCourse = stages.isSecundaria(courseOrder);
  const isPrimaryCourse = stages.isPrimaria(courseOrder);
  const isEarlyYearsCourse = stages.isInfantil(courseOrder);
  const { navigateInSameNavType, goToResource } = useNavigation();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { value: standardsReportsEnabled } = useFlag(
    'standardsreportsenabled',
    false,
    {
      identifier: '',
      custom: {
        schoolId: user?.organizationId || '',
        region: user?.region || '',
      },
    }
  );

  const { value: trainingZonePrimaryReports } = useFlag(
    'trainingzoneprimaryreports',
    false,
    {
      identifier: '',
      custom: {
        schoolId: user?.organization?.id || '',
      },
    }
  );

  const { value: showKnowMoreInfoFlag } = useFlag('showknowmoreinfo', false, {
    identifier: '',
    custom: {
      region: user?.region || '',
    },
  });

  const { value: lastYearReportBannerFlag } = useFlag(
    'lastYearReportBanner',
    false,
    {
      identifier: '',
      custom: {
        region: user?.region ?? '',
      },
    }
  );

  const { data: lastYearReportsData } =
    useLastYearReportsQuery<LastYearReportsQuery>(
      {
        id: id!,
      },
      {
        enabled: !!id,
      }
    );

  const hasLastYearReport =
    lastYearReportBannerFlag &&
    (lastYearReportsData?.lastYearReports?.find(
      (lastYearReport) => lastYearReport?.isLastYear === true
    )?.academicYear ||
      false);

  const standard =
    (standardsReportsEnabled && user?.organization?.standard) || '';

  const handleSendEvent = (event: string, parameters?: {}): void => {
    setEventData(event, {
      classroom_id: id,
      ...parameters,
    });
  };

  const handleGoToStudents = (): void => {
    navigateInSameNavType(APP_PATHS.CLASSROOM_STUDENTS_URL, '?tab=rti');
    handleSendEvent('catchup_manage_students_button');
  };

  const handleNavigate = (route: string, queryParams?: string): void => {
    navigateInSameNavType(route, queryParams);
  };

  const handleNavigateToResource = (appletId: string) => {
    goToResource({
      resourceId: appletId,
      type: 'applet',
      openInNewTab: true,
    });
  };

  const locationInfo = {
    search: location.search,
    pathname: location.pathname,
  };

  const isLastYearReport = currentRoute === LAST_YEAR_REPORTS_URL;

  const getReports = (): JSX.Element => {
    if (!id || !currentClassroom) return <></>;
    switch (submenu) {
      case 'weekly-results':
        return (
          <WeeklyResults
            setEventData={setEventData}
            isSecundaria={!!isSecundariaCourse}
            isEarlyYears={!!isEarlyYearsCourse}
            educationalStage={educationalStage}
            courses={courses}
            isNewManager={true} //TODO: remove this prop when the new manager feature flag is removed
            handleNavigateToResource={handleNavigateToResource}
            handleNavigate={handleNavigate}
            locationInfo={locationInfo}
            trainingZonePrimaryReports={trainingZonePrimaryReports}
            classroom={currentClassroom}
            hasLastYearReport={hasLastYearReport}
            isLastYearReport={isLastYearReport}
          />
        );
      case 'general-progress':
      case 'last-year':
        return (
          <GeneralProgress
            setEventData={setEventData}
            classroomId={id}
            isSecundaria={!!isSecundariaCourse}
            classroomName={currentClassroom?.name || ''}
            educationalStage={educationalStage}
            courses={courses}
            standard={standard}
            isNewManager={true} //TODO: remove this prop when the new manager feature flag is removed
            handleNavigate={handleNavigate}
            handleNavigateToResource={handleNavigateToResource}
            isLastYearReport={isLastYearReport}
            isPrimary={!!isPrimaryCourse}
            hasLastYearReport={hasLastYearReport}
          />
        );
      case 'intervention':
        return (
          <CatchUpDashboard
            classroom={currentClassroom}
            setEventData={setEventData}
            isNewManager={true} //TODO: remove this prop when the new manager feature flag is removed
          />
        );
      default:
        return <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />;
    }
  };

  const titles = {
    'weekly-results': t('reports.weeklyResults'),
    'general-progress': t('reports.generalProgress'),
    intervention: t('reports.catch-up-dashboard.header.title'),
    'last-year': t('reports.classroomLastYearReport.title'),
  };

  const descriptions = {
    intervention: t('reports.catch-up.description'),
    'last-year': t('reports.classroomLastYearReport.body'),
  };

  const getTitleForSubmenu = (
    submenu: string,
    isSecundariaCourse: boolean
  ): string | undefined => {
    if (isLastYearReport && isSecundariaCourse) {
      return '';
    }

    if (submenu in titles) {
      return titles[submenu as keyof typeof titles];
    }
    return undefined;
  };

  const getDescriptionForSubmenu = (
    submenu: string,
    isSecundariaCourse: boolean
  ): string | undefined => {
    if (isLastYearReport && isSecundariaCourse) {
      return '';
    }
    if (submenu in descriptions) {
      return descriptions[submenu as keyof typeof descriptions];
    }
    return undefined;
  };

  const getHeaderReportsProps = (): HeaderReportsProps | BaseProps => {
    const baseProps = {
      title: getTitleForSubmenu(submenu!, !!isSecundariaCourse) || '',
      description:
        getDescriptionForSubmenu(submenu!, !!isSecundariaCourse) || '',
      descriptionTestId: 'INTRE_DESCRIPTION',
    };

    if (submenu === 'intervention') {
      return {
        ...baseProps,
        actions: (
          <Button
            onClick={handleGoToStudents}
            variant="secondary"
            dataTestId="INTRE_MANAGESTUDENTSBUTTON"
          >
            {t('reports.catch-up-dashboard.button.manageStudents')}
          </Button>
        ),
        descriptionLink: t('reports.catch-up.description.link'),
      };
    }

    return baseProps;
  };
  const { closeFullScreen } = useReportsFullScreen();

  const hasShowKnowMoreInfoFlag = isPrimaryCourse && showKnowMoreInfoFlag;

  useEffect(() => {
    if (submenu === 'general-progress') closeFullScreen();
  }, [submenu]);
  return (
    <>
      {hasShowKnowMoreInfoFlag && (
        <KnowMoreDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
      <Container>
        <HeaderReports
          actions={
            <>
              {hasShowKnowMoreInfoFlag && (
                <Actions>
                  <Typography.Link2
                    onClick={() => {
                      setIsDrawerOpen(true);
                    }}
                  >
                    {t('reports.personalizedPractice.knowMore.title')}
                  </Typography.Link2>
                </Actions>
              )}
            </>
          }
          {...getHeaderReportsProps()}
        />
        <TableContainer id="reports-table-container">
          {getReports()}
          <div id="portal-root" />
        </TableContainer>
      </Container>
    </>
  );
}
