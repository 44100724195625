import { Region } from '@innovamat/localization';
import { StandardContent } from '@innovamat/resource-viewer';
import { Printable } from '../types/Materials';
import {
  PreprModel,
  SmartGuideBase,
  SmartGuidePrimaria,
  SmartGuideSecundaria,
  SmartGuideTaller,
  SmartGuideType,
} from '../types/SmartGuide';
import { Stage } from '../types/Stage';
import { mapNewStandardContent } from './map-new-standard-content';
import { mapDimensionTags } from './mapDimensionTags';
import { mapMaterials } from './mapMaterials';
import { groupParts } from './mapPart';

const modelToKey = (model: string) => {
  const modelKey = {
    SmartGuideTaller: 'ey',
    SmartGuidePrimaria: 'pe',
    SmartGuideSecundaria: 'se',
    SmartGuideOpen: 'se',
  };

  return modelKey[model as keyof typeof modelKey];
};

const guideTypeMap = (tipo: string) => {
  const guideType = {
    1: 'lab',
    2: 'adv',
    3: 'us',
    4: '4in1',
  };

  return guideType[+tipo as keyof typeof guideType];
};

export function mapSmartGuide(
  data: any,
  stage: Stage,
  model: PreprModel,
  printables: Printable[],
  assessables?: string[],
  standardContent?: StandardContent[],
  region?: Region
): SmartGuideType {
  const smartGuide: SmartGuideBase = {
    type: 'smartGuide',
    id: data._id,
    sessionCode: data.session_code,
    summary: {
      inThisTaller: {
        titleKey:
          modelToKey(model) === 'ey'
            ? `digitalguides.${modelToKey(model)}.workshop.summary`
            : `digitalguides.${modelToKey(model)}.summary`,
        text: data.en_este_taller,
      },
      withIntentionOf: {
        titleKey: `digitalguides.${modelToKey(model)}.with_intention_of`,
        text: data.con_intencin_de,
      },
      vocabularyKey: {
        titleKey:
          modelToKey(model) === 'se'
            ? 'digitalguides.to_consider'
            : 'digitalguides.key_vocabulary',
        text: data.vocabulario_clave,
      },
      assessables: assessables || [],
      materials: mapMaterials(data.materiales, printables, stage),
      dimensionTags: mapDimensionTags(data.dimensiones),
      newStandardContent: mapNewStandardContent({
        data,
        stage,
        standardContent,
        region,
      }),
    },
    subtitle: data.subtitle,
    title: data.title,
    parts: groupParts(data, modelToKey(model), guideTypeMap(data.tipo)),
  };

  if (model === 'SmartGuidePrimaria') {
    const processedSmartGuide: SmartGuidePrimaria = {
      __typename: 'SmartGuidePrimaria',
      ...smartGuide,
      guideType: data.tipo,
    };
    return processedSmartGuide;
  }

  if (model === 'SmartGuideSecundaria' || model === 'SmartGuideOpen') {
    const processedSmartGuide: SmartGuideSecundaria = {
      __typename: 'SmartGuideSecundaria',
      ...smartGuide,
    };

    return processedSmartGuide;
  }

  const processedSmartGuide: SmartGuideTaller = {
    __typename: 'SmartGuideTaller',
    ...smartGuide,
  };

  return processedSmartGuide;
}
