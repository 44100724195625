import { Applet } from '../types/applet';
import { Genially } from '../types/genially';
import { Geogebra } from '../types/geogebra';
import { Manipulative } from '../types/manipulative';
import { Page, Pdf } from '../types/pdf';
import {
  ResourceResponse,
  ResourceViewResponse,
  StandardContentReponse,
} from '../types/resourceResponse';
import { ResourceType } from '../types/resourceType';
import {
  Guide,
  GuideType,
  Session,
  SessionResource,
  StandardContent,
} from '../types/session';
import { Video } from '../types/video';
import checkMissingLocale from '../utils/missingLocales';
import { mapPrintable } from './contentsMapper';
import { Params } from './resourceMapper';

const resourceValues = ['1', '2', '3'];
const guideValue = 'guide';

const mapGuide = (session: ResourceResponse): Guide | undefined => {
  if (session.digital_guide?.id) {
    return {
      id: session.digital_guide.id,
      name: '',
      type: session.digital_guide.type,
      path: '',
    };
  }

  const dataGuide = session.resource_blocks?.find(
    (block) => block.name === guideValue
  )?.resources[0]?.resource_view;

  if (!dataGuide) return undefined;

  return {
    id: dataGuide.uuid,
    name: dataGuide.name,
    type: dataGuide.type.toLowerCase() as GuideType,
    path: dataGuide.file?.href || '',
  };
};

export const getPages = (
  resource_view: ResourceViewResponse,
  isStudent: boolean
) => {
  const fragments = resource_view.file?.fragments || [];
  let subResources: Page[] = [];

  subResources = fragments.map((fragment, index) => ({
    order: index,
    isBloqued: isStudent && fragment.visibility !== 'visible',
  }));

  return subResources;
};

const mapResources = ({
  response,
  locale,
  isStudent = false,
}: ResourceParams): SessionResource[] => {
  const blocks = response.resource_blocks?.filter((block) =>
    resourceValues.includes(block.name)
  );

  if (!blocks) return [];

  const mappedBlocks = blocks?.flatMap(({ resources, name }) =>
    resources
      .sort((a, b) => a.order - b.order)
      .map(({ resource_view }, index): SessionResource => {
        const base = {
          title: resource_view.name,
          id: resource_view.uuid,
          isBloqued: false,
          language: locale,
          type: resource_view.type as ResourceType,
          group: name,
          order: index,
          tags: resource_view.tags,
        };
        const isVisibilityNotVisible =
          isStudent && resource_view.visibility !== 'visible';

        if (resource_view.type === ResourceType.vimeo) {
          const vimeo: Video = {
            ...base,
            url: resource_view.file?.href!,
            fileId: resource_view.file?.uuid || '',
            isBloqued: isVisibilityNotVisible,
            progress: resource_view.progress || 0,
          };
          return vimeo as SessionResource;
        }

        if (resource_view.type === ResourceType.pdf) {
          const pdf: Pdf = {
            ...base,
            path: resource_view.file?.href!,
            fileId: resource_view.file?.uuid!,
            pages: getPages(resource_view, isStudent),
          };
          return pdf as SessionResource;
        }

        if (resource_view.type === ResourceType.genially) {
          const genially: Genially = {
            ...base,
            src: resource_view.file?.href!,
            isBloqued: false,
          };
          return genially as SessionResource;
        }

        if (resource_view.type === ResourceType.applet) {
          const applet: Applet = {
            ...base,
            appletCompleted: false,
            packOrVariation: resource_view.pack!,
            sceneName: resource_view.scene_name!,
            status: resource_view.applet_status,
            language: locale,
            onAppletIsCompleted: undefined,
            payload: undefined,
            isBloqued: isVisibilityNotVisible,
            thumbnail: resource_view.thumbnail.href,
          };
          return applet as SessionResource;
        }

        if (resource_view.type === ResourceType.geogebra) {
          const geogebra: Geogebra = {
            ...base,
            settings: resource_view.settings,
            file: resource_view.file?.href!,
            script: resource_view.script!,
            isBloqued: isVisibilityNotVisible,
          };
          return geogebra as SessionResource;
        }

        const manipulative: Manipulative = {
          ...base,
          isBloqued: false,
          isManipulative: true,
        };
        return manipulative as SessionResource;
      })
  );

  return mappedBlocks;
};

type ResourceParams = Params & {
  done: boolean;
  solutionsEnabled: boolean;
};

const mapStandardContent = (
  standardContent?: StandardContentReponse[]
): StandardContent[] => {
  return (
    standardContent?.map((content) => ({
      blockKey: content.block_key,
      blockValue: content.block_value,
      content: content.content.map((contentValue) => ({
        contentKey: contentValue.content_key,
        contentValue: contentValue.content_value,
      })),
    })) || []
  );
};

const sessionMapper = ({
  response,
  locale,
  completedResources,
  isStudent = false,
}: Params) => {
  const completedValues = completedResources?.find(
    (resource) => resource.resourceId === response.uuid
  );

  const done = !!completedValues;
  const solutionsEnabled = completedValues?.solutionsEnabled || false;

  const guide = mapGuide(response);
  const isSession = true;
  const printables = mapPrintable(response, isStudent, isSession);

  const resources = mapResources({
    response,
    locale,
    isStudent,
    done,
    solutionsEnabled,
  });

  return {
    id: response.uuid,
    title: checkMissingLocale(response.name!),
    tags: response.tags,
    type: response.type as ResourceType,
    description: checkMissingLocale(response.description!),
    guide,
    printables,
    resources,
    linkedLists: response.linked_lists?.default as any[],
    relatedLists: response.linked_lists?.default,
    assessableLists: response.linked_lists?.assessable,
    isBloqued: false,
    language: locale,
    thumbnail: response.thumbnail?.href,
    code: response.code,
    done_status: response.done_status,
    standardContent: mapStandardContent(response.standard_content),
  } satisfies Session;
};

export default sessionMapper;
